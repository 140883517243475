/* eslint-disable lines-between-class-members */
import BaseSearchFilter from './BaseSearchFilter'

export class ShippingLabelFilter extends BaseSearchFilter {
  /** @type {string} */
#labelType = ''

/** @returns {Object} filter object */
getFilters() {
  return {
    labelType: this.labelType,
    accountNo: this.accountNo,
    companyId: this.companyId,
    storeId: this.storeId,
    warehouseId: this.warehouseId,
    beginDate: this.beginDate,
    endDate: this.endDate,
    tenantType: this.tenantType,
    q: this.q,
  }
}
/** @returns {string} */
get labelType() {
  return this.#labelType
}
/** @param {string} value */
set labelType(value) {
  this.#labelType = value
}
}
export default new ShippingLabelFilter()
