<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <div class="card px-1">
          <dx-data-grid
              id="labelGrid"
              ref="labelGridRef"
              :data-source="labelDataSource"
              :height="setHeight"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :row-alternation-enabled="true"
              @initialized="onInitialized"
              @content-ready="onContentReady"
              @toolbar-preparing="onToolbarPreparing($event)"
              @editor-preparing="onEditorPreparing"
            >
              <dx-scrolling column-rendering-mode="standard" />
              <dx-paging :page-size="pagerOptions.pageSize" />
              <dx-remote-operations :paging="true" :sorting="false" :filtering="false" />
              <dx-sorting mode="single" />
              <dx-pager
                :allowed-page-sizes="pagerOptions.allowedPageSizes"
                :show-info="pagerOptions.showInfo"
                :show-navigation-buttons="pagerOptions.showNavigationButtons"
                :show-page-size-selector="pagerOptions.showPageSizeSelector"
                :visible="pagerOptions.visible"
              />
              <dx-search-panel
                :highlight-search-text="true"
                :search-visible-columns-only="true"
                :visible="true"
                :width="300"
                placeholder="Search..."
              />
              <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="100" :allow-resizing="false" :fixed="true" fixed-position="left" />
              <template #itemActions="{data}">
                <div>
                  <dx-util-button
                    type="danger" icon="bi bi-x-circle"
                    hint="Void Label" class="mr-half"
                    @click="voidLabel(data)"
                  />
                  <dx-util-button
                    type="default" icon="bi bi-cloud-arrow-down-fill"
                    hint="Download"
                    @click="showLabelList(data)"
                  />
                </div>
              </template>
              <dx-column
                data-field="creationTime"
                caption="Shipped At"
                data-type="date"
                width="120"
              />
              <dx-column
                data-field="trackingNumber"
                caption="Tracking Number"
                width="300"
                cell-template="trackingNumberTemplate"
              />
              <template #trackingNumberTemplate="{data}">
                  <a :href="data.row.data.trackingUrlProvider" target="_blank">
                      {{ data.value }}
                  </a>
              </template>
              <dx-column
                v-if="hasPermission"
                data-field="customerAccountNo"
                caption="Account No"
                width="100"
                cell-template="accountCell"
              />
              <dx-column
                v-if="hasPermission"
                data-field="customerSuiteNo"
                caption="Suite No"
                width="100"
                cell-template="accountCell"
              />
              <template #accountCell="{ data }">
                <a href="#" @click="onFilterByAccountNoLink($event, data)">
                  <span>{{ data.value }}</span>
                </a>
              </template>
              <dx-column
                data-field="storeName"
                caption="Store Name"
              />
              <dx-column
                data-field="referenceKey"
                caption="Type"
                cell-template="referenceKeyTemplate"
              />
              <template #referenceKeyTemplate="{data}">
                <div v-if="data.value">
                  {{ data.value.replace(/_/g, ' ') }}
                </div>
                <div v-else>
                  -
                </div>
              </template>
              <dx-column data-field="buySource" width="100" cell-template="buySourceTemplate" />
              <template #buySourceTemplate="{ data }">
                <div class="text-center">
                  <p-lazy-img v-if="data.value === 'PSH'" height="28px" width="auto" :src="require(`@/assets/images/logo/prep.svg`) || defaultImg" />
                  <p-lazy-img v-if="data.value === 'AMAZON_LABEL'" height="28px" width="auto" :src="require(`@/assets/images/svg/ecommerce/amazon.svg`) || defaultImg" />
                  <p-lazy-img v-if="data.value !== 'AMAZON_LABEL' && data.value !== 'PSH'" height="28px" width="auto" :src="require(`@/assets/images/svg/warehouse/015-courier.svg`) || defaultImg" />
                </div>
              </template>/>
              <dx-column
                :visible="isServingTenant && hasPermission"
                data-field="buyPrice"
                caption="Tenant Cost"
                :format="{ style: 'currency', precision: 2, currency: 'USD' }"
                width="120"
              />
              <dx-column
                data-field="sellPrice"
                caption="Cost"
                :format="{ style: 'currency', precision: 2, currency: 'USD' }"
                width="120"
              />
              <dx-column data-field="carrier" width="150" cell-template="carrierCellTemplate" />
              <template #carrierCellTemplate="{ data }">
                <a :href="data.row.data.trackingUrlProvider" target="_blank">
                  <b-img center height="24" :src="require(`@/assets/images/svg/carrier/${data.value.toLowerCase()}.svg`)" :title="data.value" />
                </a>
              </template>/>
              <dx-column data-field="trackingStatus" width="150" cell-template="trackingStatusTemplate" />
              <template #trackingStatusTemplate="{ data }">
                <div :class="resolveTrackingStatusFormat(data.value)" class="d-block">
                  {{ data.value }}
                </div>
              </template>
              <dx-column data-field="transactionStatus" width="150" cell-template="transactionStatusTemplate" />
              <template #transactionStatusTemplate="{ data }">
                <div :class="resolveTransactionStatusFormat(data.value)" class="d-block">
                  {{ data.value }}
                </div>
              </template>
              <dx-column
                data-field="notes"
                caption="Notes"
              />
              <dx-column
                data-field="invoiceId"
                caption="Invoice"
                cell-template="invoiceCell"
                width="100"
                :visible="isServingTenant"
              />
              <template #invoiceCell="{data}">
                <router-link
                  v-if="data.value"
                  :to="{ name: 'apps-invoice-preview', params: { id: data.value } }"
                >
                  <div class="text-capitalize badge badge-pill badge-light-success  d-block">
                    Invoice
                  </div>
                </router-link>
              </template>
               <!--Begin    ::DataGrid toolbar -->
              <template #toolbarFilters>
                <div class="d-flex flex-row align-items-center">
                  <div v-if="hasPermission" class="mr-1">
                    <dx-util-text-box
                      v-model="accountNo"
                      :show-clear-button="true"
                      mode="text"
                      placeholder="Account or Suite No"
                      @key-down="onFilterByAccountNo"
                      @value-changed="onFilterByAccountNo"
                    />
                  </div>
                  <div class="mr-1">
                    <dx-util-select-box
                      v-model="selectedStore"
                      :data-source="stores"
                      display-expr="text"
                      value-expr="value"
                      @value-changed="searchByFilter"
                    />
                  </div>
                  <div class="mr-1">
                    <dx-util-select-box
                      v-model="selectedLabelType"
                      :data-source="shippingLabelTypes"
                      display-expr="text"
                      value-expr="value"
                      @value-changed="searchByFilter"
                    />
                  </div>
                  <div class="mr-1">
                    <dx-util-select-box
                      v-model="selectedDateRange"
                      :data-source="dateRangesList"
                      :drop-down-options="{width: '180px'}"
                      display-expr="text"
                      value-expr="value"
                      @value-changed="setDateRanges"
                    />
                  </div>
                  <div v-show="isCustomDateRange" class="mr-1">
                    <dx-util-date-box v-model="beginDate" :max="endDate" type="date" @value-changed="searchByFilter" />
                  </div>
                  <div v-show="isCustomDateRange" class="mr-1">
                    <dx-util-date-box v-model="endDate" :min="beginDate" type="date" @value-changed="searchByFilter" />
                  </div>
                  <div v-if="isOwner" class="mr-half">
                    <deposit-widget />
                  </div>
                  <div class="mr-half">
                    <current-credit-widget />
                  </div>
                </div>
              </template>
              <!--End    ::DataGrid toolbar -->
            </dx-data-grid>
          </div>
      </div>

      <label-form :component-id="labelFormComponentId" @on-label-form-closed="closePopup" />

      <dx-util-popup
        ref="downloadLabelPopupRef"
        :close-on-outside-click="true"
        :show-title="true"
        :drag-enabled="false"
        position="center"
        :width="500"
        height="auto"
        title="Shipping Label List "
      >
        <template #content>
          <div>
          <div v-for="(label, index) in downloadLabelList" :key="index">
            <div class="d-flex justify-content-between align-items-center p-half border-bottom">
            <a :href="label.trackingUrlProvider" target="_blank">
              {{ label.trackingNumber }}
            </a>
             <dx-util-button icon="bi bi-cloud-arrow-down-fill" hint="Download" type="text" css-class="text-success mr-1" @click="downloadLabel(label.labelUrl)" />
             <dx-util-button v-if="label.commercialInvoiceUrl" icon="bi bi-globe" text="Commercial Invoice" hint="Commercial Invoice" type="text" css-class="text-success" @click="downloadLabel(label.commercialInvoiceUrl)" />
          </div>
          </div>
        </div>
        </template>
      </dx-util-popup>
    </div>
  </div>
</template>

<script>
import predefinedDateRangesEnum, { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum.js'
import useDateRanges from '@/@core/composables/useDateRanges.js'
import useCurrentUser from '@/libs/app/current-user'
import ShippingLabelFilter from '@/http/models/search-filters/ShippingLabelFilter'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import { currencyFormatter } from '@core/utils/filter'
import ShippingLabelTypesEnum, { getShippingLabelTypeList } from '@/enums/shippingLabelTypes.enum.js'
import shipmentLabelService from '@/http/requests/external/shipmentLabels.js'
import { Notify } from '@/@robustshell/utils'
import DepositWidget from '@/views/apps/finance/balances/DepositWidget.vue'
import CurrentCreditWidget from '@/views/apps/finance/balances/CurrentCreditWidget.vue'
import { v4 as uuidv4 } from 'uuid'
import { labelDataSource } from './labelStore'
import LabelForm from './labels/LabelForm.vue'

export default {
  components: {
    LabelForm,
    DepositWidget,
    CurrentCreditWidget,
  },
  mixins: [GridBase, Filters],
  data() {
    const firstDayOfMonth = new Date()
    const plusOneDay = new Date()
    firstDayOfMonth.setDate(1)
    firstDayOfMonth.setHours(0, 0, 0, 1)
    plusOneDay.setDate(plusOneDay.getDate() + 1)
    return {
      labelDataSource,
      shippingLabelTypes: getShippingLabelTypeList(),
      selectedLabelType: ShippingLabelTypesEnum.ALL.value,
      selectedAmzStatus: 'ALL',
      filters: {
        query: '',
      },
      accountNo: null,
      beginDate: firstDayOfMonth,
      endDate: plusOneDay,
      labelFormComponentId: '',
      showCostInfo: false,
      downloadLabelList: [],
      selectedDateRange: predefinedDateRangesEnum.LAST30DAYS.value,
      dateRangesList: getPredefinedDateRangesList(),
      selectedStore: 0,
    }
  },
  setup() {
    const { setDateRanges, startDate, isCustomDateRange } = useDateRanges()
    const {
      isServingTenant, userRoleName,
    } = useCurrentUser()
    return {
      setDateRanges, startDate, isCustomDateRange, isServingTenant, userRoleName,
    }
  },
  computed: {
    dataGrid() {
      return this.$refs.labelGridRef.instance
    },
    downloadLabelsPopup() {
      return this.$refs.downloadLabelPopupRef.instance
    },
    oneMonthAgo() {
      const date = new Date()
      date.setMonth(date.getMonth() - 1)
      return date
    },
    plusOneDay() {
      const date = new Date()
      date.setDate(date.getDate() + 1)
      return date
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    isOwner() {
      return this.userRoleName === 'WOWNER' || this.userRoleName === 'SUPERUSER' || this.userRoleName === 'IOWNER'
    },
  },
  watch: {
    startDate(newValue) {
      this.beginDate = newValue
    },
  },
  mounted() {
    this.initialLoad()
    this.getStores()
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          text: 'Buy',
          type: 'default',
          stylingMode: 'contained',
          onClick: () => {
            this.labelFormComponentId = uuidv4()
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reload()
          },
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = null
        this.getStores(this.accountNo)
        this.searchByFilter()
      }
    },
    onFilterByAccountNoLink(e, row) {
      this.accountNo = row.value.toString()
      this.searchByFilter()
    },
    getCurrencyFormat(data) {
      return currencyFormatter(data.value)
    },
    normalizeText(cellInfo) {
      return this.$options.filters.capitalize(cellInfo.valueText)
    },
    percentDiscountText(cellInfo) {
      return `${cellInfo.valueText}%`
    },
    toggleDefault() {
      this.showCostInfo = !this.showCostInfo
    },
    initialLoad() {
      ShippingLabelFilter.accountNo = null
      ShippingLabelFilter.labelType = this.selectedLabelType
      ShippingLabelFilter.beginDate = this.oneMonthAgo
      ShippingLabelFilter.endDate = this.plusOneDay
      const filters = ShippingLabelFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    reload() {
      this.selectedLabelType = ShippingLabelTypesEnum.ALL.value
      this.accountNo = null
      this.query = ''
      this.beginDate = this.oneMonthAgo
      this.endDate = this.plusOneDay
      this.selectedStore = 0
      ShippingLabelFilter.accountNo = this.accountNo
      ShippingLabelFilter.labelType = this.selectedLabelType
      this.selectedDateRange = predefinedDateRangesEnum.LAST30DAYS.value
      ShippingLabelFilter.beginDate = this.beginDate
      ShippingLabelFilter.endDate = this.endDate
      ShippingLabelFilter.q = this.filters.query
      const filters = ShippingLabelFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    searchByFilter() {
      ShippingLabelFilter.accountNo = parseInt(this.accountNo, 10) || null
      ShippingLabelFilter.labelType = this.selectedLabelType
      ShippingLabelFilter.beginDate = this.beginDate
      ShippingLabelFilter.endDate = this.endDate
      ShippingLabelFilter.q = this.filters.query
      ShippingLabelFilter.q = this.filters.query
      ShippingLabelFilter.storeId = this.selectedStore
      const filters = ShippingLabelFilter.getFilters()
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    async voidLabel(e) {
      const shipmentOperationId = e.row.data.shipmentOperationId
      shipmentLabelService.getTrackingsWithSameReference(shipmentOperationId).then(response => {
        if (response.length === 1) {
          this.$swal({
            title: 'Void Shipping Label!',
            text: 'Are you sure you want to void this shipping label?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Void',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              shipmentLabelService.voidShippingLabel(shipmentOperationId).then(() => {
                this.reload()
              })
            }
          })
        } else if (response.length > 1) {
          const trackingNumbers = response.map(item => item.trackingNumber).toString()
          this.$swal({
            title: 'Warning',
            text: `There are other labels with the same reference. ${trackingNumbers} Do you want to void them all?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Void All',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              shipmentLabelService.voidShippingLabel(shipmentOperationId).then(() => {
                this.$swal({
                  icon: 'success',
                  title: 'Shipping label voided',
                  text: 'All labels with the same reference voided',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.reload()
              })
            }
          })
        } else {
          Notify.warning('An error occurred to fetch labels with same reference number')
        }
      })
    },
    showLabelList(e) {
      this.downloadLabelList = []
      const shipmentOperationId = e.row.data.shipmentOperationId
      shipmentLabelService.getTrackingsWithSameReference(shipmentOperationId).then(response => {
        this.downloadLabelList = response
        this.downloadLabelsPopup.show()
      })
    },
    downloadLabel(url) {
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    closePopup() {
      this.reload()
    },
    resolveTransactionStatusFormat(status) {
      if (status === 'SUCCESS') return 'badge badge-light-success'
      if (status === 'QUEUED') return 'badge badge-light-warning'
      if (status === 'ERROR') return 'badge badge-light-danger'
      if (status === 'REFUNDPENDING') return 'badge badge-light-danger'
      if (status === 'REFUNDED') return 'badge badge-light-primary'
      if (status === 'REFUNDREJECTED') return 'badge badge-light-danger'
      if (status === 'WAITING') return 'badge badge-light-warning'
      return 'badge badge-light-primary'
    },
    resolveTrackingStatusFormat(status) {
      if (status === 'UNKNOWN') return 'badge badge-light-warning'
      if (status === 'PRE_TRANSIT') return 'badge badge-light-primary'
      if (status === 'TRANSIT') return 'badge badge-light-primary'
      if (status === 'DELIVERED') return 'badge badge-light-success'
      if (status === 'RETURNED') return 'badge badge-light-success'
      if (status === 'FAILURE') return 'badge badge-light-danger'
      return 'badge badge-light-primary'
    },
  },
}
</script>

<style>
.label-tool {
  word-spacing: 0.5em; /* Adjust the value as needed */
}
</style>
