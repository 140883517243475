import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import shipmentLabelService from '@/http/requests/external/shipmentLabels'
import Pager from '@core/dev-extreme/utils/pager'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: loadOptions => {
    const options = {}
    const pager = new Pager(options)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    const pageableString = pager.toCreatePageable()
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    return shipmentLabelService
      .getAllShippingLabels(filters, pageableString)
      .then(response => {
        const data = response
        return {
          data: data.content,
          totalCount: data.totalElements,
        }
      })
  },
})

const labelDataSource = new DataSource({
  store: store,
})

export {
  labelDataSource,
}
